import React from "react"
import {
  ContactForm,
  Layout,
  SyllabusBanner,
  SyllabusHeader,
  HelmetWrapper,
  SyllabusTexts,
} from "../components"
import Frantisek from "../images/photos/frantisek.jpg"
import SyllabusSlider from "../components/syllabus/syllabus-slider"
import { SYLLABUS_SLIDER } from "../model/syllabus-slider-data"
import OgImage from "../images/syllabus-og-images/3.png"

const LECTURER = [
  {
    src: Frantisek,
    alt: "",
    personName: "František Rajtmajer",
    text: [
      "František se zabývá webovou analytikou a PPC správou na volné noze. Díky své praxi propojuje tyto dva obory, a pomáhá tak firmám řídit on-line marketing na základě dat.",
      "V rámci Digisemestru on-line vede lekce webové analytiky. Žije v Plzni, vaří si vlastní pivo a píše blog nazakladedat.cz.",
    ],
    linkedInProfile: "//linkedin.com/in/frantisekrajtmajer",
  },
]

const NastaveniReportingu: React.FC = () => {
  return (
    <Layout>
      <HelmetWrapper title="Nastavení reportingu v GA4" ogImage={OgImage} />

      <SyllabusHeader
        orangeText="Nastavení reportingu"
        blackText="v GA4"
        numberOfLesson="3/13"
      ></SyllabusHeader>

      <SyllabusBanner data={LECTURER} />

      <SyllabusTexts>
        <div>reporting</div>
        <div>explore</div>
        <div>DS</div>
        <div>napojení BQ a jeho představení</div>
        <div>
          důraz nejen na technické nastavení, ale i přemýšlení – nevytvářet
          report podle zadání „toto chci v reportu”, ale „toto je problém, který
          potřebuji vyřešit”
        </div>
      </SyllabusTexts>

      <SyllabusSlider data={SYLLABUS_SLIDER} />

      <ContactForm
        heading="Máš otázku?"
        subheading="Napiš si o odpověď Adamovi"
      />
    </Layout>
  )
}

export default NastaveniReportingu
